import React from 'react'

const DetalleNoticiaImagenFooter = ({ url_base, picture }) => {
  return ( 
    <>
        <div className='detalle__noticia-content-footer'>
          <img src={`${url_base}package/web_iesap/img/actualidad/noticias/detail/${picture}`} alt="" className='detalle__noticia-content-footer-img'/>
        </div>
    </>
  );
}
 
export default DetalleNoticiaImagenFooter;